<template>
  <v-app>
    <v-main :class="pageHasBackground() ? '' : 'no-background'">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    pageHasBackground() {
      return this.$route.meta.background;
    },
  },
};
</script>

<style lang="scss" scoped>
// .v-application {
//   background-color: #e5e5e5 !important;
// }
</style>
