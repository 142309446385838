<template>
  <div class="reservation">
    <div class="header">
      <v-img src="../assets/logo.png" class="logo" width="200px"></v-img>
    </div>
    <div class="content">
      <div class="notfound">
        <!-- <h1>Welcome To JAMALOUK</h1> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.notfound h1 {
  margin: 9% auto;
  font-size: 200px;
  text-align: center;
  color: #fff;
}
@font-face {
  font-family: "jannaLtBold";
  src: local("jannaLtBold"),
    url(../assets/fonts/JannaLTBold/JannaLTBold.ttf) format("truetype");
}
$second-color: #ff7dac;
* {
  font-family: "jannaLtBold";
}
.white-text {
  color: #000;
}
.gray-text {
  color: #888888 !important;
}
.black-text {
  color: #000 !important;
}
.second-text {
  color: #ff7dac;
}

.reg-details {
  border: 1px solid #a53860;
  padding: 20px;
  border-radius: 25px;
}

.header {
  .logo {
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 33423;
  }
}
.content {
  // height: 100%;
  .title,
  .brand-name {
    font-size: 1.5rem !important;
  }
  .location {
    font-size: 1rem;
  }
  // background-color: #e5e5e5;
  // padding-bottom: 50px;
  //   height: 50vh;
  .form-card {
    width: 900px;
    height: 560px;
    // overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    // position: relative;
    // margin-top: -150px !important;
    .right-col {
      height: 630px;
      border-top: 5px solid #a53860;
      border-radius: 0 0 90px 0;
      padding: 1rem 2rem;
      background-color: #fff;
      color: #ffffff;
      margin-bottom: 20px !important;
    }
    .left-col {
      height: 630px;
      border-bottom: 5px solid #fff;
      border-radius: 90px 0 0 0;
      padding: 1rem 2rem;
      background-color: #2d3533;
      color: #ffffff;
      margin-top: 20px;
    }
    .bottom-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 150px;
      opacity: 0.5;
    }
  }
}
@media (min-width: 960px) {
  .form-card {
    max-width: 50%;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .form-card {
    max-width: 80%;
  }
}

@media (max-width: 600px) {
  .form-card {
    max-width: 80%;
  }
}
.rounded-input {
  border-radius: 20px;
}
.countrycode {
  direction: ltr !important;
  text-align: center !important;
  padding-right: 0 !important;
}
.countrycode-col {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.countrycode-col .v-text-field__slot input {
  text-align: center !important;
}
.main-btn {
  background-color: #a53860 !important;
  color: #ffffff !important;
  border-radius: 5px 5px 20px 5px;
  width: 150px;
  font-size: 1.2rem !important;
}

.success-content {
  .success-msg {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .reg-details {
    border: 1px solid #cccccc;
    padding: 1rem 2rem;
    color: #ffffff;
    border-radius: 20px;
    ul {
      list-style: none;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
