import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: "https://backend.jamalouk.app/api",
  },
  getters: {
    base_url(state) {
      return state.apiUrl;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
