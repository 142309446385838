import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Reservation from "../views/reservation.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { background: true },
  },
  {
    path: "/reservation/:slug",
    name: "Reservation",
    component: Reservation,
    meta: { background: true },
  },
  {
    path: "/confirmed/:id",
    name: "reservation.confirmed",

    component: () => import("../views/after_reservation.vue"),
    meta: { background: true },
  },
  {
    path: "/invoice/:invoice_number",
    name: "invoicePage",

    component: () => import("../views/invoice.vue"),
    meta: { background: false },
  },
  {
    path: "*",
    name: "NotFoundComponent",
    component: () => import("../views/404.vue"),
    meta: { background: true },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
