import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import jQuery from "jquery";
window.jQuery = window.$ = jQuery;
import * as VueGoogleMaps from "vue2-google-maps";

require("./assets/custom.css");

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDfpn-3DVmT9xAsbQ-kWN4Rl4oeGO5E4wk",
    libraries: "places",
  },
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
